import React from 'react';
import { Icon } from './styles';

export const ShipIcon = () => {
  return (
    <Icon>
      <svg
        fill="#fff"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 512 512"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <g>
              <rect x="135.995" y="152.159" width="16.001" height="15.674" />
              <rect x="168.001" y="152.159" width="16.001" height="15.674" />
              <rect x="199.996" y="152.159" width="16.001" height="15.674" />
              <rect x="232.002" y="152.159" width="16" height="15.674" />
              <rect x="263.997" y="152.159" width="16.001" height="15.674" />
              <rect x="296.002" y="152.159" width="16.001" height="15.674" />
              <rect x="327.997" y="152.159" width="16.001" height="15.674" />
              <rect x="360.003" y="152.159" width="16" height="15.674" />
              <rect x="183.1" y="0.162" width="144.657" height="15.674" />
              <rect x="368.164" y="7.999" width="15.674" height="16.001" />
              <path
                d="M385.036,424.161c32.784-19.519,54.801-55.315,54.801-96.162v-60.843l-32-16.001V112.161H104.163v138.994l-32,16v60.843
				c0,40.848,22.016,76.644,54.801,96.163H0v15.674h512v-15.674H385.036z M119.837,127.835h272.326v64.327H289.849l-33.85-16.924
				l-33.849,16.924H119.837V127.835z M392.162,207.836v35.483l-70.966-35.483H392.162z M119.837,207.836h70.966l-70.966,35.483
				V207.836z M87.837,276.843L256,192.761l168.164,84.082v22.476L256,215.237L87.837,299.319V276.843z M183.999,424.162
				c-53.025,0-96.163-43.138-96.163-96.163v-11.156l168.163-84.082l168.164,84.082v11.156c0,53.025-43.138,96.163-96.163,96.163
				H183.999z"
              />
              <path
                d="M408,88.162h-0.001h-24.164V31.999h-15.674v56.163h-40.326v-64H184.163v64h-40.326V31.999h-15.674v56.163H104v15.674h304
				V88.162z M312.163,88.162H199.837V39.836h112.326V88.162z"
              />
              <rect x="128.158" y="7.999" width="15.674" height="16.001" />
              <rect x="64.001" y="456.165" width="39.999" height="15.674" />
              <rect x="112.004" y="480.156" width="79.998" height="15.674" />
              <rect x="24" y="480.156" width="24" height="15.674" />
              <rect x="247.999" y="464.159" width="24.001" height="15.674" />
              <rect x="279.994" y="480.156" width="72" height="15.674" />
              <rect x="360.003" y="496.164" width="24" height="15.674" />
              <rect x="416" y="464.159" width="95.996" height="15.674" />
            </g>
          </g>
        </g>
      </svg>
    </Icon>
  );
};
