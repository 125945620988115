export const ru = {
  main: {
    aboutNav: 'О нас',
    serviceNav: 'Услуги',
    contact: 'Контакты',
    aboutTitle: 'О нас',
    about:
      '   ООО "Международная компания "ИМПЕКСГРУП" успешно\n' +
      '                работает на рынке Украины с 1999 года и предоставляет широкий\n' +
      '                спектр услуг в сфере грузоперевозок автомобильным, воздушным,  морским и железнодорожным транспортом, хранения,\n' +
      '                сопровождения и таможенного оформления грузов.',
    aboutParagraph:
      ' Мы постоянно расширяем перечень грузов и географию поставок,\n' +
      '                  предлагаем клиентам выгодные условия сотрудничества,\n' +
      '                  гарантируем высокий уровень безопасности перевозимых товаров.\n' +
      '                  Цель нашей компании – обеспечивать логистический сервис,\n' +
      '                  который полностью отвечает современным европейским и мировым\n' +
      '                  стандартам.',
    service: 'Наши услуги',
    serviceShip: 'Морские перевозки',
    serviceAir: 'Авиаперевозки грузов',
    serviceTrain: 'Железнодорожные перевозки',
    serviceTruck: 'Автомобильные перевозки',
    form: 'Подать заявку',
    formName: 'Имя',
    formEmail: 'E-mail',
    formTelephone: 'Телефон',
    formFrom: 'Откуда',
    formTo: 'Куда',
    formDescription: 'Наименование груза, вес, объём',
    fileUploader: 'Загрузить файл',
    formSubmit: 'Отправить',
    success: 'Спасибо за заявку!',
    success1: 'Очень скоро с вами свяжется наш мененджер',
    error: 'Что-то пошло не так...',
    association: 'Членство в ассоциациях:',
  },
  air: {
    h1: 'Авиаперевозки',
    description1:
      'Авиаперевозки грузов по Украине и в любую точку мира – одно из основных направлений деятельности нашей компании. Такой способ доставки поможет сократить общие затраты на логистику, переместить объект или ценную партию на любое расстояние за считанные часы, отправить товар в отдаленные районы с ограниченной инфраструктурой, куда прочими видами транспорта добраться невозможно. Широкая география перевозок и система аэропортов по всему миру позволят вашему бизнесу выйти на качественно новый уровень, а также расширить список клиентов и партнеров.',
    h2: 'Преимущества международных авиаперевозок грузов',
    li1: 'Сроки особенно важны, когда нужно доставить из точки А в точку В грузовые партии, дорогостоящие товары, скоропортящиеся продукты и др.Воздушный транспорт преодолевает дальние расстояния, препятствия в виде гор, пустынь, морей и океанов;',
    span1: 'Высокая скорость доставки. ',
    li2: 'По статистике наименьшее число аварий приходится именно на долю авиаперевозок;',
    span2: 'Безопасность полетов. ',
    li3: 'Особенности погрузочно-разгрузочного процесса являются гарантией того, что товары будут доставлены в целости и сохранности;',
    span3: 'Надежность. ',
    li4: 'Как правило, при авиаперевозках товары сразу доставляют из аэропорта к месту назначения;',
    span4: 'Экономия на складских услугах. ',
    li5: 'Страховка обойдется в 0,3% от стоимости груза, что в 2 раза дешевле по сравнению с морским транспортом.',
    span5: 'Размер страховых премий. ',
    p1: 'Перевозить таким способом допустимо товары любых габаритов. На борт можно погрузить строительные материалы, медикаменты, крупные и тяжеловесные предметы, такие как автомобили. Возможна доставка опасных, ценных и живых грузов.',
    h3: 'Выгоды сотрудничества с нашей компанией',
    li6: 'Резервируем грузовую квоту;',
    li7: 'Предлагаем таможенно-брокерское сопровождение;',
    li8: 'Разрабатываем оптимальную маршрутную схему;',
    li9: 'Занимаемся перевозками специальных грузов (AVI, HUM, VAL, DIP, PER, MUW, DGR и др.);',
    li10: 'Оптимизируем затраты клиента, предлагая самые выгодные условия;',
    li11: 'Осуществляем прием груза в аэропорту, транспортировку до конечного пункта назначения;',
    li12: 'Отслеживаем статус груза по авианакладной;',
    li13: 'Гарантируем регулярность рейсов и отправок;',
    li14: 'Оказываем полный комплекс услуг в сфере ВЭД;',
    li15: 'Оформляем документы (авианакладная, инвойс, упаковочный лист, паспорт безопасности для авиаперевозок);',
    li16: 'Страхуем грузы;',
    li17: 'Наши тарифы на 15% ниже рыночных цен.',
    p2: 'ООО "Международная компания "ИМПЕКСГРУП" перевозит грузы из Китая, США, Германии, Канады, Таиланда и многих других стран мира. В процессе работы мы учитываем все нюансы, помогаем клиенту выбрать оптимальный способ доставки продукции, организовываем грузовые перевозки по маршруту любой сложности.Специалисты компании предлагают широкий спектр дополнительных услуг, таких как бронирование мест, оформление документов, складирование товаров ипогрузочно-разгрузочные работы.',
    h4: 'Тарифы на авиа доставку с Китая под ключ с растаможкой',
    li18: 'Хоз группа (одежда, обувь, аксессуары, игрушки, текстиль, бижутерия, фурнитура, сувениры, чехлы) НЕ БРЕНД 14$/1кг',
    li19: 'Автомобильные запчасти (фары, блоки управления, фильтра, тормозные диски ) НЕ БРЕНД Стекла, пленки для телефонов НЕ БРЕНД 14,5 Сумки кож. НЕ БРЕНД 15,4',
    li20: 'Сапоги кожа. мех НЕ БРЕНД 15,4 Мед.материалы, ресницы НЕ БРЕНД 16,3',
    li21: 'Электроника без батарей 16,4',
    li22: 'Техника (кух. машины, кофеварки, чайники, колонки) 16,4',
    li23: 'Осветительные приборы (станки, осветительные приборы) 16,4',
    li24: 'Станки 16,4',
    li25: 'Компьютерные аксессуары (видео карта, винчестер, блок питания, провода) НЕ БРЕНД 16',
    li26: 'Дисплеи НЕ БРЕНД 16,5',
    li27: 'Косметика НЕ БРЕНД 16,9',
    li28: 'Принтеры 3D НЕ БРЕНД 16,3',
    li29: 'Чай, флешки для телефонов 16,2',
    li30: 'Бренд 17,5',
  },
  ship: {
    h1: 'Морские перевозки',
    description1:
      'Популярность морских перевозок по международным маршрутам неуклонно растет.Сегодня это одна из наиболее востребованных и экономически выгодных логистических услуг, позволяющая осуществлять транспортировку партий грузов разных габаритовмежду странами и материками. Такой способ доставки товаров подходит как для больших корпораций, так и для малых предприятий, желающих безопасно перевезти стройматериалы, сырье, медицинские препараты и многое другое без лишних переплат.',
    p: 'ООО "Международная компания "ИМПЕКСГРУП" организовывает морские перевозки в Украину из Азии, США, Европы, Африки и в обратном направлении с 1999 года, помогает собрать и оформить документы на груз, гарантирует безопасную доставку товаров и четкое соблюдение сроков.',
    h2: 'Преимущества морских перевозок:',
    li1: 'Риск повреждения товара и его хищения исключен благодаря отсутствию многократной перегрузки на всем пути следования;',
    span1: 'Безопасность.',
    li2: 'Экономия.',
    span2:
      'Учитывая низкое потребление топлива кораблем, морские перевозки являются наиболее выгодным способом транзита по сравнению с другими методами транспортировки товара;',
    li3: 'Перевозить суднами можно различные группы товаров (стандартные, крупногабаритные, наливные, сыпучие, химические, длинномерные, специфические, скоропортящиеся и т.д.).',
    span3: 'Универсальность.',
    h3: 'Комплекс предлагаемых услуг',
    p1: 'В процессе организации морских перевозок на дальние расстояния ООО "Международная компания "ИМПЕКСГРУП" предлагает клиентам широкий спектр квалифицированных услуг, выгодные условия сотрудничества, а также индивидуальные опции при транспортировке стандартных, специальных, тяжелых и опасных грузов. Долгосрочные партнерские отношения с судоходными контейнерными линиями позволяет нам осуществлять быструю доставку товаров по всему миру, минимизировать расходы заказчика на перевозку и экспедирование.',
    p2: 'Мы предоставляем полный комплекс услуг в сфере морских перевозок:',
    li6: 'консолидированные поставки во все крупные порты мира в контейнерах разных типов (20/40 футов, DryContainer (DC), HighCube (HC), Open op, FlatRack, REF, 45’HC);',
    li7: 'выбор оптимального маршрута следования;',
    li8: 'работы по погрузке и разгрузке товаров;',
    li9: 'сбор и оформление сопроводительной документации (справка о декларировании валютных ценностей, инвойс + перевод, экспортная декларация,СМR, платежное поручение и многое другое); • портовое сопровождение;',
    li10: 'портовое сопровождение;',
    li11: 'подбор кодов ТНВЭД;',
    li12: 'отслеживание груза и его текущего местонахождения;',
    li13: 'бесплатные консультации;',
    li14: 'расчет сроков и стоимости доставки;',
    li15: 'оформление груза на таможне;',
    li16: 'импорт и экспорт «под ключ».',
    p3: 'Если прямое сообщение между отправителем и получателем груза отсутствует, наша компании предлагает мультимодальные перевозки. Такой способ подразумевает доставку товаров двумя и более видами транспортав рамках одного договора.',
    p4: 'Воспользуйтесь преимуществами сети ресурсов по всему миру и конкурентоспособными тарифами – закажите услуги перевозки в нашей компании, и будьте уверены, что ваш груз окажется в нужном месте и в нужное время. Специалисты компании помогут быстро пройти таможенное оформление, составят оптимально выгодный маршрут, полностью проконтролируют движение контейнера на всех отрезках пути.',
  },
  truck: {
    h1: 'Автомобильные перевозки',
    description1:
      'Международные и региональные автомобильные перевозки остаются одним из самых популярных способов доставки товаров для большинства компаний. С помощью грузоперевозок, осуществляемых наземным транспортом, можно выстроить индивидуальные маршруты, которые будут удобны как заказчику, так и отправителю, транспортировать различные группы товаров (газообразные вещества, наливные, насыпные, скоропортящиеся, негабаритные, тяжеловесные и опасные грузы).',
    h2: 'Преимущества сотрудничества с нами',
    p: 'ООО "Международная компания "ИМПЕКСГРУП" предоставляет полный комплекс услуг для физических и юридических лиц по организации грузоперевозок автомобильным транспортом на любые расстояния. Нами освоены маршруты доставки товара во все регионы страны, в Европу, Азию и обратно. Учитывая особенности каждого заказа, мы создаем прямые или мультимодальные маршруты, подбираем наиболее эффективные и безопасные варианты транспортировки сырья, продукции и изделий.',
    p1: 'Сотрудничество с ООО "Международная компания "ИМПЕКСГРУП" имеет массу преимуществ:',
    li1: 'выгодные тарифы, которые на 15% ниже рыночных цен;',
    li2: 'минимизация затрат на доставку грузов попутным транспортом в любом направлении;',
    li3: 'составление индивидуальных маршрутов, подходящих под нужды и запросы клиента;',
    li4: 'доставка «от двери до двери»;',
    li5: 'отправка груза в день подачи заявки;',
    li6: 'перевозка различных товаров с возможностью их группировки;',
    li7: 'безопасность грузоперевозок (весь транспорт оснащен GPS-датчиками, системами видеонаблюдения).',
    p2: 'Наша компания имеет все технические возможности для перевозки жидких, сыпучих, негабаритных, крупногабаритных, тяжеловесных и опасных грузов. Помимо стандартной грузоперевозки мы предоставляем возможность транспортировки товаров «под ключ». Специалисты возьмут на себя решение всех вопросов от начала и до конца, позаботятся о том, чтобы груз прибыл к месту назначения максимально быстро.',
    h3: 'Перечень услуг',
    p3: 'Помимо организации доставки грузов ООО "Международная компания "ИМПЕКСГРУП" предлагает целый ряд дополнительных услуг, среди них:',
    li21: 'растаможка товаров;',
    li22: 'оформление полного пакета документов, включая бумаги, требующиеся для прохождения границы;',
    li23: 'постоянный мониторинг местонахождения транспортного средства;',
    li24: 'упаковка, погрузка и разгрузка товаров;',
    li25: 'страхование груза.',
    p4: 'Тариф на перевозку зависит от множества факторов, среди которых сроки доставки, вес и габариты, продолжительность маршрута и особенности перевозимого товара, количество дополнительных услуг. Чтобы узнать точную стоимость транспортировки товара, обратитесь к нашим экспертам. Специалисты бесплатно проконсультируют и выполнят оперативные заказы любой сложности, профессиональные водители, имеющие все допуски и разрешения на осуществление деятельности, доставят продукцию оперативно и без рисков.',
  },
  train: {
    h1: 'Железнодорожные перевозки',
    description:
      'Железнодорожные перевозки – это способ доставки грузов, который возник в конце 1820-х годов, но пользуется популярностью во всем мире и сегодня. Такой метод транспортировки подходит для различных категорий товаров, крупногабаритных грузов, пищевых продуктов, промышленных установок, сырья для металлургии, тяжелой техники. Благодаря развитой железнодорожной сети в Украине и странах Европы доставка грузов осуществляется в самые труднодоступные и отдаленные регионы.',
    p1: 'ООО "Международная компания "ИМПЕКСГРУП" с 1999 года организует процесс международных ЖД грузоперевозок, используя контейнеры, платформы, крытые вагоны и цистерны, доставляя грузы всех видов и любых объемов. Мы предлагаем клиентам целый перечень дополнительных услуг, среди которых:',
    li1: 'оформление всей разрешительной документации;',
    li2: 'расчет таможенных платежей;',
    li3: 'выбор оптимального транспортного маршрута;',
    li4: 'подача платформы под погрузку или выгрузку товара;',
    li5: 'отслеживание перемещений вагонов и контейнеров 24/7;',
    li6: 'погрузочно-разгрузочные работы на станциях;',
    li7: 'оплата ж/д тарифов;',
    li8: 'организация охранного сопровождения товаров в пути;',
    li9: 'страхование грузов;',
    li10: 'размещение перевозимых товаров на складах.',
    p2: 'ООО "Международная компания "ИМПЕКСГРУП" – это надежный партнер в организации международных железнодорожных перевозок. Мы позаботимся о своевременной доставке товаров, подберем максимально выгодные варианты транспортировки любой продукции, возьмем на себя все сложности, которые могут возникнуть в процессе формирования документации, такелажа промышленного оборудования, выбора вагонов под требования клиента.',
    p3: 'Преимущества международных ЖД грузоперевозок',
    span1: 'Точные сроки доставки. ',
    li21: 'Даже в сложных погодных и климатических условиях движение железнодорожного транспорта осуществляется строго по графику;',
    span2:
      'Возможность комбинировать разные варианты вагонов в грузовом составе. ',
    li22: 'Благодаря этому достигается максимальная экономичность транспортировки, обеспечивается сохранность каждого вида груза;',
    span3: 'Универсальность. ',
    li23: 'Традиционный способ доставки подходит для тяжеловесных или негабаритных товаров. Перевозить можно все: от стройматериалов и животных, до автомобилей и химических реагентов;',
    span4: 'Приемлемая цена. ',
    li24: 'Железная дорога занимает 2-ое место по доступности. Дешевле обойдется лишь морская грузоперевозка.',
    p4: 'Предлагаемые нашей компанией транспортные услуги востребованы предприятиями разной величины, география бизнеса которых охватывает Украину и другие страны. Обращаясь к нам, клиенты могут быть уверены, что специалисты решат любые вопросы, связанные с ввозом и сертификацией товаров, позаботятся о целостности грузов, предложат эффективные и надежные способы доставки в любую точку мира.',
  },
};
