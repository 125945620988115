import React from 'react';
import { Icon } from './styles';

export const TrainIcon = () => {
  return (
    <Icon>
      <svg
        fill={'#fff'}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 470 470"
        enableBackground="new 0 0 470 470"
      >
        <g>
          <path d="m170,50.021h120c4.143,0 7.5-3.358 7.5-7.5s-3.357-7.5-7.5-7.5h-120c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5 7.5,7.5z" />
          <path d="m115.513,256.753c-12.407,0-22.5,10.093-22.5,22.5s10.093,22.5 22.5,22.5 22.5-10.093 22.5-22.5-10.093-22.5-22.5-22.5zm0,30c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5,3.364 7.5,7.5-3.364,7.5-7.5,7.5z" />
          <path d="m354.488,256.753c-12.406,0-22.5,10.093-22.5,22.5s10.094,22.5 22.5,22.5 22.5-10.093 22.5-22.5-10.094-22.5-22.5-22.5zm0,30c-4.136,0-7.5-3.364-7.5-7.5s3.364-7.5 7.5-7.5 7.5,3.364 7.5,7.5-3.365,7.5-7.5,7.5z" />
          <path d="M360.089,0H109.911c-25.86,0-46.898,21.039-46.898,46.898v277.355c0,4.142,3.358,7.5,7.5,7.5h2.5v50.748   c0,15.164,12.336,27.5,27.5,27.5h11.893l-17.193,17.193c-0.008,0.008-30.004,30.004-30.004,30.004   c-2.145,2.145-2.787,5.371-1.626,8.173c1.161,2.803,3.896,4.63,6.929,4.63h328.975c3.033,0,5.769-1.827,6.929-4.63   c1.161-2.803,0.52-6.028-1.625-8.173l-30-29.999c-0.001-0.001-17.197-17.197-17.197-17.197h11.893c15.163,0,27.5-12.336,27.5-27.5   v-50.748h2.5c4.143,0,7.5-3.358,7.5-7.5V46.898C406.988,21.039,385.95,0,360.089,0z M109.911,15h250.178   c17.589,0,31.898,14.31,31.898,31.898v23.143H78.013V46.898C78.013,29.31,92.323,15,109.911,15z M391.988,316.753H78.013V85.042   H227.5v140.104H100.513c-4.142,0-7.5,3.358-7.5,7.5s3.358,7.5,7.5,7.5h268.975c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H242.5   V85.042h149.487V316.753z M208.376,331.753h53.248v33.248h-53.248V331.753z M88.62,455l15-15h262.761l15,15H88.62z M351.381,425   H118.62l15-15h202.761L351.381,425z M381.988,382.501c0,6.893-5.607,12.5-12.5,12.5H100.513c-6.893,0-12.5-5.607-12.5-12.5v-50.748   h105.363v40.748c0,4.142,3.358,7.5,7.5,7.5h68.248c4.143,0,7.5-3.358,7.5-7.5v-40.748h105.363V382.501z" />
        </g>
      </svg>
    </Icon>
  );
};
