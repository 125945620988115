export const uk = {
  main: {
    aboutNav: 'Про нас',
    serviceNav: 'Послуги',
    contact: 'Контакти',
    aboutTitle: 'Про нас',
    about:
      ' ТОВ "Міжнародна компанія "ІМПЕКСГРУП" успішно\n' +
      ' працює на ринку України з 1999 року і надає широкий\n' +
      ' спектр послуг у сфері вантажоперевезень автомобільним, повітряним, морським та залізничним транспортом, зберігання,\n' +
      'супроводження та митного оформлення вантажів.',
    aboutParagraph:
      'Ми постійно розширюємо перелік вантажів та географію поставок,\n' +
      'пропонуємо клієнтам вигідні умови співпраці,\n' +
      ' гарантуємо високий рівень безпеки товарів, що перевозяться.\n' +
      'Мета нашої компанії - забезпечувати логістичний сервіс,\n' +
      ' який повністю відповідає сучасним європейським та світовим\n' +
      'стандартів.',
    service: 'Нашi послуги',
    serviceShip: 'Морські перевезення',
    serviceAir: 'Авіаперевезення вантажів',
    serviceTrain: 'Залізничні перевезення',
    serviceTruck: 'Автомобільні перевезення',
    form: 'Подати заяву',
    formName: `Ім'я`,
    formEmail: 'E-mail',
    formTelephone: 'Телефон',
    formFrom: 'Звідки',
    formTo: 'Куди',
    formDescription: "Найменування вантажу, вага, об'єм",
    fileUploader: 'Завантажити файл',
    formSubmit: 'Відправити',
    success: 'Дякуємо за завку!',
    success1: "Незабаром з вами зв'яжеться наш менеджер",
    error: 'Щось пішло не так...',
    association: 'Членство в асоціаціях:',
  },
  air: {
    h1: 'Авіаперевезення',
    description1:
      "Авіаперевезення вантажів по Україні та в будь-яку точку світу – один із основних напрямків діяльності нашої компанії. Такий спосіб доставки допоможе скоротити загальні витрати на логістику, перемістити об'єкт чи цінну партію на будь-яку відстань за лічені години, відправити товар до віддалених районів з обмеженою інфраструктурою, куди іншими видами транспорту дістатися неможливо. Широка географія перевезень та система аеропортів по всьому світу дозволять вашому бізнесу вийти на якісно новий рівень, а також розширити список клієнтів та партнерів.",
    h2: 'Переваги міжнародних авіаперевезень вантажів',
    li1: 'Терміни особливо важливі, коли потрібно доставити з точки А в точку В вантажні партії, дорогі товари, продукти, що швидко псуються та ін. Повітряний транспорт долає далекі відстані, перешкоди у вигляді гір, пустель, морів і океанів;',
    span1: 'Висока швидкість доставки. ',
    li2: 'За статистикою найменша кількість аварій припадає саме на частку авіаперевезень;',
    span2: 'Безпека польотів. ',
    li3: 'Особливості вантажно-розвантажувального процесу є гарантією того, що товари будуть доставлені в цілості та безпеці;',
    span3: 'Надійність. ',
    li4: 'Як правило, при авіаперевезеннях товари одразу доставляють з аеропорту до місця призначення;',
    span4: 'Заощадження на складських послугах. ',
    li5: 'Страховка обійдеться в 0,3% вартості вантажу, що в 2 рази дешевше в порівнянні з морським транспортом.',
    span5: 'Розмір страхових премій. ',
    p1: 'Перевозити у такий спосіб допустимо товари будь-яких габаритів. На борт можна завантажити будівельні матеріали, медикаменти, великі та важкі предмети, такі як автомобілі. Можлива доставка небезпечних, цінних та живих вантажів.',
    h3: 'Вигоди співпраці з нашою компанією',
    li6: 'Резервуємо вантажну квоту;',
    li7: 'Пропонуємо митно-брокерський супровід;',
    li8: 'Розробляємо оптимальну маршрутну схему;',
    li9: 'Займаємося перевезеннями спеціальних вантажів (AVI, HUM, VAL, DIP, PER, MUW, DGR та ін.);',
    li10: 'Оптимізуємо витрати клієнта, пропонуючи найвигідніші умови;',
    li11: 'Здійснюємо прийом вантажу в аеропорту, транспортування до кінцевого пункту призначення;',
    li12: 'Відстежуємо статус вантажу по авіанакладній;',
    li13: 'Гарантуємо регулярність рейсів та відправок;',
    li14: 'Надаємо повний комплекс послуг у сфері ЗЕД;',
    li15: 'Оформляємо документи (авіанакладна, інвойс, пакувальний лист, паспорт безпеки для авіаперевезень);',
    li16: 'Страхуємо вантажі;',
    li17: 'Наші тарифи на 15% нижчі від ринкових цін.',
    p2: 'ТОВ "Міжнародна компанія "ІМПЕКСГРУП" перевозить вантажі з Китаю, США, Німеччини, Канади, Таїланду та багатьох інших країн світу. У процесі роботи ми враховуємо всі нюанси, допомагаємо клієнту вибрати оптимальний спосіб доставки продукції, організовуємо вантажні перевезення за маршрутом будь-якої складності. Фахівці компанії пропонують широкий спектр додаткових послуг, таких як бронювання місць, оформлення документів, складування товарів та вантажно-розвантажувальні роботи.',
    h4: 'Тарифи на авіадоставку з Китаю під ключ із розмитненням',
    li18: 'Хоз група (одяг, взуття, аксесуари, іграшки, текстиль, біжутерія, фурнітура, сувеніри, чохли) НЕ БРЕНД 14$/1кг',
    li19: 'Автомобільні запчастини (фари, блоки керування, фільтри, гальмівні диски) НЕ БРЕНД Скло, плівки для телефонів НЕ БРЕНД 14,5 Сумки шкір. НЕ БРЕНД 15,4',
    li20: 'Шкіра чоботи. хутро НЕ БРЕНД 15,4 Мед.матеріали, вії НЕ БРЕНД 16,3',
    li21: 'Електроніка без батарей 16,4',
    li22: 'Техніка (кух. машини, кавоварки, чайники, колонки) 16,4',
    li23: 'Освітлювальні прилади (верстати, освітлювальні прилади) 16,4',
    li24: 'Верстати 16,4',
    li25: "Комп'ютерні аксесуари (відео карта, вінчестер, блок живлення, дроти) НЕ БРЕНД 16",
    li26: 'Дисплеї НЕ БРЕНД 16,5',
    li27: 'Косметика НЕ БРЕНД 16,9',
    li28: 'Принтери 3D НЕ БРЕНД 16,3',
    li29: 'Чай, флешки для телефонів 16,2',
    li30: 'Бренд 17,5',
  },
  ship: {
    h1: 'Морські перевезення',
    description1:
      'Популярність морських перевезень міжнародними маршрутами неухильно зростає. Сьогодні це одна із найбільш затребуваних та економічно вигідних логістичних послуг, що дозволяє здійснювати транспортування партій вантажів різних габаритів між країнами та материками. Такий спосіб доставки товарів підходить як для великих корпорацій, так і для малих підприємств, які бажають безпечно перевезти будматеріали, сировину, медичні препарати та багато іншого без зайвих переплат.',
    p: 'ТОВ "Міжнародна компанія "ІМПЕКСГРУП" організовує морські перевезення в Україну з Азії, США, Європи, Африки та у зворотному напрямку з 1999 року, допомагає зібрати та оформити документи на вантаж, гарантує безпечну доставку товарів та чітке дотримання термінів.',
    h2: 'Переваги морських перевезень:',
    li1: 'Ризик пошкодження товару та його розкрадання виключено завдяки відсутності багаторазового перевантаження по всьому шляху прямування;',
    span1: 'Безпека. ',
    li2: 'Враховуючи низьке споживання палива кораблем, морські перевезення є найвигіднішим способом транзиту у порівнянні з іншими методами транспортування товару;',
    span2: 'Економія. ',
    li3: 'Перевозити суднами можна різні групи товарів (стандартні, великогабаритні, наливні, сипучі, хімічні, довгомірні, специфічні, що швидко псуються тощо).',
    span3: 'Універсальність. ',
    h3: 'Комплекс пропонованих послуг',
    p1: 'У процесі організації морських перевезень на далекі відстані ТОВ "Міжнародна компанія "ІМПЕКСГРУП" пропонує клієнтам широкий спектр кваліфікованих послуг, вигідні умови співпраці, а також індивідуальні опції при транспортуванні стандартних, спеціальних, важких та небезпечних вантажів. Довгострокові партнерські відносини із судноплавними контейнерними лініями дозволяє нам здійснювати швидку доставку товарів у всьому світі, мінімізувати витрати замовника на перевезення та експедирування.',
    p2: 'Ми надаємо повний комплекс послуг у сфері морських перевезень:',
    li6: 'консолідовані поставки у всі великі порти світу в контейнерах різних типів (20/40 футів, DryContainer (DC), HighCube (HC), Open op, FlatRack, REF, 45’HC);',
    li7: 'вибір оптимального маршруту проходження;',
    li8: 'роботи з навантаження та розвантаження товарів;',
    li9: 'збір та оформлення супровідної документації (довідка про декларування валютних цінностей, інвойс + переказ, експортна декларація, СМR, платіжне доручення та багато іншого);',
    li10: 'портовий супровід;',
    li11: 'підбір кодів ТНЗЕД;',
    li12: 'відстеження вантажу та його поточного місцезнаходження;',
    li13: 'безкоштовні консультації;',
    li14: 'розрахунок термінів та вартості доставки;',
    li15: 'оформлення вантажу на митниці;',
    li16: 'імпорт та експорт «під ключ».',
    p3: 'Якщо пряме сполучення між відправником та одержувачем вантажу відсутнє, наша компанія пропонує мультимодальні перевезення. Такий спосіб передбачає доставку товарів двома і більше видами транспорту у межах одного договору.',
    p4: 'Скористайтеся перевагами мережі ресурсів по всьому світу та конкурентоспроможними тарифами – замовте послуги перевезення в нашій компанії, і будьте впевнені, що ваш вантаж опиниться у потрібному місці та у потрібний час. Фахівці компанії допоможуть швидко пройти митне оформлення, складуть оптимально вигідний маршрут, повністю проконтролюють рух контейнера на всіх відрізках шляху.',
  },
  truck: {
    h1: 'Автомобільні перевезення',
    description1:
      'Міжнародні та регіональні автомобільні перевезення залишаються одним із найпопулярніших способів доставки товарів для більшості компаній. За допомогою вантажоперевезень, що здійснюються наземним транспортом, можна вибудувати індивідуальні маршрути, які будуть зручні як замовнику, так і відправнику, транспортувати різні групи товарів (газоподібні речовини, наливні, насипні, швидкопсувні, негабаритні, важковагові та небезпечні вантажі).',
    h2: 'Переваги співпраці з нами',
    p: 'ТОВ "Міжнародна компанія "ІМПЕКСГРУП" надає повний комплекс послуг для фізичних та юридичних осіб з організації вантажоперевезень автомобільним транспортом на будь-які відстані. Нами освоєно маршрути доставки товару у всі регіони країни, до Європи, Азії та назад. Враховуючи особливості кожного замовлення, ми створюємо прямі або мультимодальні маршрути, підбираємо найбільш ефективні та безпечні варіанти транспортування сировини, продукції та виробів.',
    p1: 'Співпраця з ТОВ "Міжнародна компанія "ІМПЕКСГРУП" має безліч переваг:',
    li1: 'вигідні тарифи, які на 15% нижчі від ринкових цін;',
    li2: 'мінімізація витрат на доставку вантажів попутним транспортом у будь-якому напрямку;',
    li3: 'ння індивідуальних маршрутів, що підходять під потреби та запити клієнта;',
    li4: 'доставка «від дверей до дверей»;',
    li5: 'відправлення вантажу у день подання заявки;',
    li6: 'перевезення різних товарів із можливістю їх угруповання;',
    li7: 'безпека вантажоперевезень (весь транспорт оснащений GPS-датчиками, системами відеоспостереження).',
    p2: 'Наша компанія має всі технічні можливості для перевезення рідких, сипких, негабаритних, великогабаритних, великовагових та небезпечних вантажів. Окрім стандартного вантажоперевезення ми надаємо можливість транспортування товарів «під ключ». Фахівці візьмуть на себе вирішення всіх питань від початку та до кінця, подбають про те, щоб вантаж прибув до місця призначення максимально швидко.',
    h3: 'Перелік послуг',
    p3: 'Крім організації доставки вантажів ТОВ "Міжнародна компанія "ІМПЕКСГРУП" пропонує низку додаткових послуг, серед них:',
    li21: 'розмитнення товарів;',
    li22: 'оформлення повного пакета документів, включаючи папери, які потрібні для проходження кордону;',
    li23: 'постійний моніторинг місцезнаходження транспортного засобу;',
    li24: 'пакування, навантаження та розвантаження товарів;',
    li25: 'страхування вантажу.',
    p4: 'Тариф на перевезення залежить від безлічі факторів, серед яких терміни доставки, вага та габарити, тривалість маршруту та особливості товару, що перевозиться, кількість додаткових послуг. Щоб дізнатися точну вартість транспортування товару, зверніться до наших експертів. Фахівці безкоштовно проконсультують та виконають оперативні замовлення будь-якої складності, професійні водії, які мають усі допуски та дозволи на здійснення діяльності, доставлять продукцію оперативно та без ризиків.',
  },
  train: {
    h1: 'Железнодорожные перевозки',
    description:
      'Железнодорожные перевозки – это способ доставки грузов, который возник в конце 1820-х годов, но пользуется популярностью во всем мире и сегодня. Такой метод транспортировки подходит для различных категорий товаров, крупногабаритных грузов, пищевых продуктов, промышленных установок, сырья для металлургии, тяжелой техники. Благодаря развитой железнодорожной сети в Украине и странах Европы доставка грузов осуществляется в самые труднодоступные и отдаленные регионы.',
    p1: 'ООО "Международная компания "ИМПЕКСГРУП " с 1999 года организует процесс международных ЖД грузоперевозок, используя контейнеры, платформы, крытые вагоны и цистерны, доставляя грузы всех видов и любых объемов. Мы предлагаем клиентам целый перечень дополнительных услуг, среди которых:',
    li1: 'оформление всей разрешительной документации;',
    li2: 'расчет таможенных платежей;',
    li3: 'выбор оптимального транспортного маршрута;',
    li4: 'подача платформы под погрузку или выгрузку товара;',
    li5: 'отслеживание перемещений вагонов и контейнеров 24/7;',
    li6: 'погрузочно-разгрузочные работы на станциях;',
    li7: 'оплата ж/д тарифов;',
    li8: 'организация охранного сопровождения товаров в пути;',
    li9: 'страхование грузов;',
    li10: 'размещение перевозимых товаров на складах.',
    p2: 'ООО "Международная компания "ИМПЕКСГРУП " – это надежный партнер в организации международных железнодорожных перевозок. Мы позаботимся о своевременной доставке товаров, подберем максимально выгодные варианты транспортировки любой продукции, возьмем на себя все сложности, которые могут возникнуть в процессе формирования документации, такелажа промышленного оборудования, выбора вагонов под требования клиента.',
    p3: 'Преимущества международных ЖД грузоперевозок',
    span1: 'Точные сроки доставки. ',
    li21: 'Даже в сложных погодных и климатических условиях движение железнодорожного транспорта осуществляется строго по графику;',
    span2:
      'Возможность комбинировать разные варианты вагонов в грузовом составе. ',
    li22: 'Благодаря этому достигается максимальная экономичность транспортировки, обеспечивается сохранность каждого вида груза;',
    span3: 'Универсальность. ',
    li23: 'Традиционный способ доставки подходит для тяжеловесных или негабаритных товаров. Перевозить можно все: от стройматериалов и животных, до автомобилей и химических реагентов;',
    span4: 'Приемлемая цена. ',
    li24: 'Железная дорога занимает 2-ое место по доступности. Дешевле обойдется лишь морская грузоперевозка.',
    p4: 'Предлагаемые нашей компанией транспортные услуги востребованы предприятиями разной величины, география бизнеса которых охватывает Украину и другие страны. Обращаясь к нам, клиенты могут быть уверены, что специалисты решат любые вопросы, связанные с ввозом и сертификацией товаров, позаботятся о целостности грузов, предложат эффективные и надежные способы доставки в любую точку мира.',
  },
};
