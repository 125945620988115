import React from 'react';
import { Icon } from './styles';

export const AirplaneIcon = () => {
  return (
    <Icon>
      <svg
        fill="#fff"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 470 470"
        enableBackground="new 0 0 470 470"
      >
        <g>
          <path d="m468.931,279.951c-1.355-2.26-3.797-3.642-6.431-3.642h-133.466c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5 7.5,7.5h120.966l-2.486,4.661c-2.951,5.531-14.614,11.719-25.808,11.719h-120.322c3.31-9.005 5.15-18.824 5.15-29.109 0-14.44-3.606-27.969-9.863-39.594h68.379c4.142,0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-78.854c-7.905-8.875-17.712-15.701-28.696-19.695v-75.57c0-12.009-7.447-22.804-19.435-28.173-1.95-0.873-4.181-0.873-6.131,0-11.988,5.369-19.435,16.164-19.435,28.173v75.57c-10.984,3.994-20.791,10.82-28.696,19.695h-78.853c-4.142,0-7.5,3.357-7.5,7.5s3.358,7.5 7.5,7.5h68.379c-6.257,11.625-9.863,25.154-9.863,39.594 0,10.285 1.84,20.104 5.15,29.109h-120.323c-11.193,0-22.857-6.188-25.808-11.719l-2.485-4.661h121.14c4.142,0 7.5-3.357 7.5-7.5s-3.358-7.5-7.5-7.5h-133.64c-2.634,0-5.076,1.382-6.431,3.642-1.355,2.259-1.426,5.063-0.187,7.388l8.368,15.69c5.88,11.024 23.03,19.66 39.043,19.66h32.089c-2.885,4.727-4.549,10.277-4.549,16.209 0,17.208 14,31.208 31.208,31.208s31.209-14 31.209-31.208c0-5.932-1.664-11.482-4.549-16.209h42.241c12.898,20.612 34.555,34.168 59.057,34.168s46.16-13.556 59.057-34.168h42.241c-2.885,4.727-4.549,10.277-4.549,16.209 0,17.208 14,31.208 31.209,31.208s31.208-14 31.208-31.208c0-5.932-1.664-11.482-4.549-16.209h32.089c16.013,0 33.163-8.636 39.043-19.66l8.368-15.69c1.241-2.325 1.171-5.129-0.185-7.388zm-345.68,58.947c-2.84217e-14,8.938-7.271,16.208-16.209,16.208s-16.208-7.271-16.208-16.208 7.271-16.209 16.208-16.209 16.209,7.272 16.209,16.209zm104.249-210.177c0-5.005 2.803-9.688 7.5-12.802 4.697,3.113 7.5,7.797 7.5,12.802v72.012c-2.466-0.282-4.967-0.432-7.5-0.432s-5.034,0.15-7.5,0.432v-72.012zm7.5,213.136c-31.173,0-56.534-28.386-56.534-63.277s25.361-63.278 56.534-63.278 56.534,28.387 56.534,63.278-25.361,63.277-56.534,63.277zm144.167-2.959c0,8.938-7.271,16.208-16.208,16.208s-16.209-7.271-16.209-16.208 7.271-16.209 16.209-16.209 16.208,7.272 16.208,16.209z" />
          <path d="m264.201,250.16h-58.402c-4.142,0-7.5,3.357-7.5,7.5 0,4.143 3.358,7.5 7.5,7.5h58.402c4.142,0 7.5-3.357 7.5-7.5 0-4.143-3.358-7.5-7.5-7.5z" />
        </g>
      </svg>
    </Icon>
  );
};
